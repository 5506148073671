import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { colors } from '../../theme'
import { Button, TextInput, Pulse } from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { navigate } from '../../components/Link'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import { EventContext } from '../../components/context/EventContext'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useNoValidate } from '../../hooks/useNoValidate'
import DemandeLayout from '../../components/DemandeLayout'
import {
  httpFetch,
  buildAskForQuoteBody,
  computeNextPageWithBaseUri,
} from '../../util'
import { CustomerContext } from '../../components/context/CustomerContext'

const schema = yup.object().shape({
  phone: yup
    .string()
    .matches(
      /^((?:\+|00)33|0)\s*([1-9])((?:[\s.-]*\d{2}){4})$/,
      'Ce numéro ne semble pas correct',
    ),
})

const PhonePage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const { customer, setCustomer } = useContext(CustomerContext)

  const {
    state: { contactPhone },
    state: checkoutState,
    dispatch: dispatchCheckoutCtx,
  } = useContext(CheckoutContext)

  const {
    state: eventState,
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const noValidate = useNoValidate()
  const [error, setError] = useState()

  const submitContactForm = async () => {
    if (customer) {
      httpFetch(
        `${process.env.GATSBY_VENUS_BACK_URL}/api/account/update-profile`,
        {
          method: 'POST',
          body: {
            phone: contactPhone,
            firstName: customer.firstName,
            lastName: customer.lastName,
          },
        },
      ).then(() => {
        setCustomer({
          ...customer,
          phone: contactPhone,
        })
      })
    }
    const nextUrl = computeNextPageWithBaseUri('demande', 'phone', {
      isUserLoggedIn: !!customer,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, { state: { next: true } })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        categories,
      })

      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', { state: { quote: true } })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: { demandSource: null },
        })
      }
    }
  }

  const { formal, phoneProps } = useFormalWithProps(
    {
      phone: contactPhone,
    },
    {
      schema,
      onSubmit: submitContactForm,
    },
  )

  return (
    <StyledPhonePage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Merci de préciser votre numéro de téléphone.`}
              subTitle="Cela permettra de faciliter les échanges."
            />
            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err || formal.errors)
                }
              }}
            >
              <TextInput
                id="phone"
                placeholder="06"
                type="tel"
                autoComplete="tel"
                secondary
                required
                autoFocus
                {...phoneProps}
                onChange={cp => {
                  formal.change('phone', cp)
                  dispatchCheckoutCtx({
                    type: 'UPDATE',
                    payload: {
                      contactPhone: cp,
                    },
                  })
                }}
              />

              <p className="error">{error}</p>
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit" disabled={formal.isSubmitting}>
                  {formal.isSubmitting ? <Pulse /> : <span>Suivant</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledPhonePage>
  )
}

const StyledPhonePage = styled.div`
  .page-content {
    max-width: 500px;
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }
`

export default PhonePage
export { StyledPhonePage }
